<template>
    <div class="panel feature" :class="{ expanded: expanded }">
        <h1>{{ name }}</h1>
        <div class="buttons" :class="{ 'no-website': true }">
            <button v-if="hasWebsiteUrl" @click="gotoWebsite" class="website-link">Website Link</button>
        </div>
        <p v-if="hasDescription" class="feature-description" v-html="description" v-linkify />
        <public-gallery v-if="hasGallery" :id="galleryId" />
    </div>
</template>

<script>
import { get, isEmpty } from 'utils/lodash';
import PublicGallery from 'components/layers/types/common/components/annotations/gallery/public-gallery';
import { layerDetails } from 'mixins';
export default {
    mixins: [layerDetails],
    components: {
        PublicGallery,
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        url() {
            return this.rowProperty('properties.url');
        },
        hasWebsiteUrl() {
            return this.rowProperty('properties.url_type') == 'Website' && !isEmpty(this.url);
        },
        hasDescription() {
            return !isEmpty(this.description);
        },
        description() {
            return this.rowProperty('properties.description');
        },
        hasGallery() {
            return this.galleryId > 0 && this.expanded;
        },
        galleryId() {
            return get(this.gallery, 'id');
        },
        gallery() {
            return this.rowProperty('properties.gallery');
        },
        name() {
            return this.rowProperty('properties.name');
        },
        coordinates() {
            return this.rowProperty('geometry.coordinates');
        },
    },
    methods: {
        getDirections() {
            window.open(
                `https://www.google.com/maps/dir//Current+Location/${this.coordinates[1]},${this.coordinates[0]}`
            );
        },
        gotoWebsite() {
            window.open(this.url);
        },
    },
};
</script>

<style lang="scss" scoped>
.panel {
    max-height: 300px;

    .website-link {
        display: none;
    }

    h1 {
        font-family: Avenir;
        font-size: 19px;
        font-weight: 500;
        color: #141414;
        text-overflow: ellipsis;
        margin-bottom: 0;
    }

    .buttons {
        display: grid;
        grid-template-columns: 50% 50%;

        &.no-website {
            grid-template-columns: 100%;
        }

        button {
            color: #9b9b9b;
            border: 1px solid #9b9b9b;
            border-radius: 5px;
            background-color: white;
            margin: 10px 6px;
            padding: 6px 10px;

            &.directions {
                margin-left: 0;
            }

            &.website-link {
                margin-right: 0;
            }

            &:hover {
                background-color: darken(white, 10%);
                color: darken(#9b9b9b, 10%);
            }
        }
    }

    .feature-description {
        margin-top: 10px;
        font-size: 19px;
        line-height: 1.26;
        color: #505152;

        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.expanded {
        max-height: none;

        .website-link {
            display: block;
        }

        .feature-description {
            white-space: pre-wrap;
            overflow: visible;
            text-overflow: unset;
        }

        .layer-details-mobile-view & {
            max-height: 100%;

            .feature-description {
                overflow: scroll;
            }
        }
    }
}
</style>
